import {fadeIn} from '@pernod-ricard-global-cms/jsanimations';

export default function siteHeaderJs(options = {}) {
	try {
		// CSS for site-header should usually be included in critical.scss
		const {block} = options;
		fadeIn(block);

		const heartOutline = document.querySelector(
			'.header-nav__item__better-reviews-personal-likes-image'
		);

		const heartFilled = document.querySelector(
			'.header-nav__item__better-reviews-personal-likes-image-filled'
		);
		const betterReviewCountSpan = document.querySelector(
			'.better-reviews__personal-likes__count'
		);

		function updateNavFavesDisplay() {
			if (betterReviewCountSpan.innerHTML == 0) {
				heartOutline.classList.remove('outline-hide');
				heartFilled.classList.add('filled-hide');
			} else {
				heartOutline.classList.add('outline-hide');
				heartFilled.classList.remove('filled-hide');
			}
		}
		updateNavFavesDisplay();

		const config = {attributes: true, childList: true, subtree: true};
		const callback = function (mutationList, observer) {
			for (const mutation of mutationList) {
				if (mutation.type === 'childList') {
					updateNavFavesDisplay();
				}
			}
		};
		const observer = new MutationObserver(callback);
		observer.observe(betterReviewCountSpan, config);

		const documentBody = document.body;
		const navContainer = document.querySelector('.header-nav');
		const hamburgerWrapper = document.querySelector(
			'.header-interactions__hamburger-wrapper'
		);
		const hamburger = document.querySelector(
			'.header-interactions__hamburger'
		);
		const hamburgerBars = hamburger.querySelectorAll(
			'.header-interactions__hamburger-bar'
		);
		const itemsWithChildren = navContainer.querySelectorAll(
			'a.menu-item-has-children'
		);
		const items = navContainer.querySelectorAll('li.menu-item');
		const closeSubnavs = () => {
			navContainer
				.querySelectorAll('.header-nav__item.active')
				.forEach((el) => el.classList.remove('active'));
		};

		const openMobileNav = () => {
			documentBody.classList.add('mobile-menu-active');
			closeSubnavs();
		};

		hamburgerWrapper.addEventListener('click', function () {
			if (screen.width < 1024) {
				if (!documentBody.classList.contains('mobile-menu-active')) {
					openMobileNav();
				} else {
					documentBody.classList.remove('mobile-menu-active');
				}
			}
		});

		itemsWithChildren.forEach((item) => {
			item.addEventListener('click', function (e) {
				if (screen.width < 1024) {
					e.preventDefault();
				} 
				
				const isActive = item.parentNode.classList.contains('active');

				if (!isActive) {
					closeSubnavs();
				}
				
				item.parentNode.classList.toggle('active');

				if (isActive) {
					item.style.color = '';
				} else {
					item.style.color = 'var(--copper)'; 
				}
			});
		});

		items.forEach((item) => {
			item.addEventListener('mouseleave', function (e) {
				if (screen.width > 1024) {
				closeSubnavs();
				  }
			});
		});

		window.addEventListener('resize', closeSubnavs, true);
	} catch (error) {
		console.error(error);
	}
}
